<template>
  <v-col cols="12">
    <v-form ref="form">
      <base-card>
        <v-card-title>Facturas Electrónicas</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dataFilter.dates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-text-field
                    v-model="dataFilter.dates"
                    label="Rango de fechas"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dataFilter.dates"
                  range
                >
                  <v-spacer />
                  <v-btn
                    text
                    style="background-color:rgb(204 33 40);color:white !important"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    style="background-color:rgb(204 33 40);color:white !important"
                    @click="$refs.dialog.save(dataFilter.dates)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="dataFilter.Status"
                :items="typeStatus"
                item-text="textStatus"
                item-value="valueStatus"
                label="Estado"
                prepend-inner-icon="mdi-order-bool-descending-variant"
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="dataFilter.Prefix"
                label="Prefijo"
                prepend-inner-icon="mdi-file"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="dataFilter.Consecutivo"
                label="Consecutivo Factura"
                prepend-inner-icon="mdi-note-multiple"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-btn
                class="ma-2"
                style="background-color:rgb(204 33 40);color:white !important"
                @click="searchInvoice"
              >
                Buscar
              </v-btn>
              <v-btn
                class="ma-2"
                color="GRAY"
                @click="clearForm"
              >
                Nueva Búsqueda
              </v-btn>
            </v-col>
            <v-row
              v-if="searchInvoiceProgress == true"
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col cols="6">
                <v-col
                  class="text-subtitle-1 text-center"
                  cols="12"
                >
                  Consultando Facturas
                </v-col>
                <v-progress-linear
                  color="red darken-4"
                  indeterminate
                  rounded
                  height="6"
                />
              </v-col>
            </v-row>
            <v-alert
              v-show="successAlert"
              type="success"
              text
            >
              {{ msgSuccessAlert }}
            </v-alert>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-alert
                v-if="notFoundInvoices"
                text
                type="info"
              >
                No se encontraron <strong>facturas</strong> para los filtros aplicados.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
    </v-form>
  </v-col>
</template>

<script>
  import { listStatus } from '@/data/listStatus'
  import { mapState } from 'vuex'

  export default {
    name: 'FilterInvoices',
    data: () => ({
      notFoundInvoices: false,
      searchInvoiceProgress: false,
      modal: false,
      datepickerFinal: false,
      dateFinal: null,
      typeStatus: listStatus,
      dataFilter: {},
      msgSuccessAlert: '',
      successAlert: false,

    }),
    created () {
      this.searchInvoiceProgress = true
    },
    computed: {
      ...mapState('invoice', ['listInvoice']),
    },
    watch: {
      listInvoice () {
        if (this.$store.state.invoice.listInvoice) {
          this.searchInvoiceProgress = false
          if (this.$store.state.invoice.listInvoice.length > 0) {
            this.notFoundInvoices = false
          } else if (this.$store.state.invoice.listInvoice.length == 0) {
            this.notFoundInvoices = true
          }
        }
      },
    },
    methods: {
      generateAlertSuccess () {
        this.successAlert = true
        setTimeout(() => {
          this.successAlert = false
        }, 3000)
      },
      searchInvoice () {
        this.notFoundInvoices = false
        this.searchInvoiceProgress = true
        this.$store.dispatch('invoice/getListInvoice', {
          ...this.dataFilter,
        })
      },
      clearForm () {
        this.$refs.form.reset()
      },
    },
  }
</script>

<style scoped>

</style>
